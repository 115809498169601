// http://192.168.0.62:8077/api/fontController/fontController/
import request from "@/core/request"
import { getHeaders } from "@/util";
  
  
const getAllNav = () => {
    return request.request({
        url: '/api/fontController/fontController/getAllNav',
        method: 'GET'
    })
}

const getAllNavList = () => {
    return request.request({
        url: '/api/fontController/fontController/getAllNavList',
        method: 'GET'
    })
}

const getAllAd = () => {
    return request.request({
        url: '/api/fontController/fontController/getAllAd',
        method: 'GET'
    })
}

const getAllArticle = (params) => {
    return request.request({
        url: '/api/fontController/fontController/getAllArticle',
        method: 'GET',
        params
    })
}

const getArticleDetailsById = (params) => {
    return request.request({
        url: '/api/fontController/fontController/getArticleDetails',
        method: 'GET',
        params
    })
}


const getStatistics = () => {
    return request.request({
        url: '/api/portal-web/statistics',
        method: 'GET',
    })
}

const getAllNotice = (params) => {
    // const url = `/api/portal-web/bulletin?size=${params.size}&type=${params.type[0]}${params.type.length>=2?`&type=${params.type[1]}`:''}`
    const url = `/api/portal-web/bulletin?size=${params.size}&tradeClassification=${params.tradeClassification}`
    // tradeClassification
    return request.request({
        url: url,
        method: 'GET',
    })

}

// const getImgCode = () => {
//     return request.request({
//         url: 'api/blade-auth/oauth/captcha',
//         method: 'GET'
//     })
// }


const getImgCode = () => {
    return request.request({
        url: 'api/blade-auth/oauth/captchaFont',
        method: 'GET'
    })
}
const submitUserInfo = (data) => {
    return request.request({
        url: '/api/fontController/fontController/saveTicket',
        method: 'POST',
        data
    })
}

const getTableList = (params) => {
    const url = `/api/portal-web/bulletin/list?tradeClassification=${params.tradeClassification?params.tradeClassification:''}&current=${params.current}&size=${params.size}&type=${params.type}&descs=${params.descs}&tenderProjectClassifyCodeFirst=${params.tenderProjectClassifyCodeFirst?params.tenderProjectClassifyCodeFirst[0]:''}${params.tenderProjectClassifyCodeFirst?params.tenderProjectClassifyCodeFirst.length>=2?`&tenderProjectClassifyCodeFirst=${params.tenderProjectClassifyCodeFirst[1]}`:'':''}&tenderProjectClassifyCodeSecond=${params.tenderProjectClassifyCodeSecond?params.tenderProjectClassifyCodeSecond:''}&ascs=${params.ascs}&name=${params.name}`
    return request.request({
        url: url,
        method: "GET",
    })
}

const getClassfiyCode = (params) => {
    // let dictKeyStr = ''
    // for (let i in params.dictKey) {
    //     dictKeyStr += '&dictKey=' + params.dictKey[i]
    // }
    // (dictKeyStr, 'dictKeyStr')
    const url = `/api/portal-web/tenderProjectClassifyCode/list?dictKey=${params.dictKey[0]}${params.dictKey.length>=2?`&dictKey=${params.dictKey[1]}`:''}${params.dictKey.length>=3?`&dictKey=${params.dictKey[2]}`:''}`
    return request.request({
        url: url,
        method: "GET",
    })
}

const getNewsTable = (params) => {
    return request.request({
        url: '/api/fontController/fontController/getAllArticlePage',
        method: 'GET',
        params
    })
}

const getNoticeById = (params) => {
    return request.request({
        url: "/api/portal-web/bulletin/details",
        method: "GET",
        params
    })
}

const checkIsAuthorized = () => {
    return request.request({
      url: '/api/sys/company/isAuthorized',
      headers: getHeaders(),
      method: 'GET'
    })
  }

export {
    getAllAd,
    getAllNav,
    getAllNavList,
    getAllArticle,
    getStatistics,
    getAllNotice,
    submitUserInfo,
    getImgCode,
    getTableList,
    getClassfiyCode,
    getNewsTable,
    getNoticeById,
    getArticleDetailsById,
    checkIsAuthorized
}